<template>
	<div class="text-center">
		<div v-if="noDialog">
			<EditComponentAutcomplete
				v-model="_selected"
				:propsObject="dProps"
				v-bind="$attrs"
				ref="noDialogAutocomplete">
				<template
					v-for="(_, name) in $scopedSlots"
					#[name]="slotProps">
					<slot
						:name="name"
						v-bind="slotProps || {}"></slot>
				</template>
			</EditComponentAutcomplete>
		</div>

		<v-dialog
			v-if="!noDialog"
			v-model="dialog"
			:width="width">
			<!-- SLOT FOR DIALOG WINDOW, INPUTING BUTTON -->
			<template v-slot:activator="{ on, attrs }">
				<slot
					name="activator"
					:on="on"
					:attrs="attrs">
					<v-btn
						:color="color"
						elevation="1"
						fab
						small
						v-bind="attrs"
						v-on="on"
						v-on:click="openDialog">
						<!-- {{ buttonTitle }}   -->
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</slot>
			</template>

			<v-card>
				<slot
					name="header"
					:title="title">
					<v-card-title class="headline primary">
						<slot
							name="title"
							:title="title">
							{{ title }}
						</slot>
					</v-card-title>
				</slot>
				<slot name="body">
					<v-card-text class="pa-8">
						<EditComponentAutcomplete
							v-model="_selected"
							:propsObject="dProps"
							v-bind="$attrs"
							ref="noDialogAutocomplete">
							<template
								v-for="(_, name) in $scopedSlots"
								#[name]="slotProps">
								<slot
									:name="name"
									v-bind="slotProps || {}"></slot>
							</template>
						</EditComponentAutcomplete>
					</v-card-text>
				</slot>

				<slot
					name="actions-wrapper"
					:submit="submit"
					:cancel="cancel">
					<v-card-actions class="actions">
						<slot
							name="action-buttons"
							:submit="submit"
							:cancel="cancel">
							<slot
								name="cancel-btn"
								:cancel="cancel">
								<v-btn
									text
									@click="cancel">
									Cancel
								</v-btn>
							</slot>
							<slot name="btns-filler">
								<v-spacer />
							</slot>
							<slot
								name="submit-btn"
								:submit="submit">
								<v-btn
									color="primary"
									text
									@click="submit"
									:disabled="loading">
									Submit
								</v-btn>
							</slot>
						</slot>
					</v-card-actions>
				</slot>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import EditComponentAutcomplete from "./EditComponentAutocomplete.vue";

export default {
	model: {
		event: "selected",
		prop: "selected",
	},
	components: {
		EditComponentAutcomplete,
	},
	watch: {
		/** WATCH FOR CHANGES IN SELECTED ARRAY AND FILL IT WITH NEW VALUES, PASSED TO CHILD COMPONENT */
		selectedArray(newVal) {
			this._selected = [...newVal.map((el) => el[`${this.valueKey}`])];
		},
	},

	props: {
		selected: {
			type: Array,
			default: () => [],
		},
		itemValue: String,
		cols: {
			type: Number,
			default: 1,
		},
		width: {
			type: Number,
			default: 500,
		},
		loading: {
			type: Boolean,
		},
		buttonTitle: {
			type: String,
			default: "Edit",
		},
		fullArray: {
			type: Array,
			default: function () {
				return [];
			},
		},
		selectedArray: {
			type: Array,
			default: function () {
				return [];
			},
		},
		searchIn: {
			type: [Array, String],
			default: () => [],
		},
		labelKey: {
			type: String,
			default: "",
		},
		valueKey: {
			type: String,
		},
		color: {
			type: String,
			default: "primary",
		},
		title: {
			type: String,
			default: "you forgot title",
		},
		placeholderText: {
			type: String,
		},
		maxChips: {
			type: Number,
			default: 5,
		},
		noShorten: {
			type: Boolean,
			default: false,
		},
		noDialog: {
			type: Boolean,
			default: false,
		},
		noModel: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			updateKey: 1,
			dialog: false,
			model: [],
		};
	},

	computed: {
		_selected: {
			get() {
				return this.noModel ? this.model : this.selected;
			},
			set(val) {
				this.noModel ? (this.model = val) : this.$emit("selected", val);
			},
		},
		dProps() {
			return {
				loading: this?.loading,
				items: this?.fullArray ?? [],
				key: this?.updateKey,
				maxChips: this?.maxChips,
				noShorten: this?.noShorten,
				label: this?.placeholderText,
				"item-text": this?.labelKey,
				searchfields: Array.isArray(this?.searchIn)
					? this?.searchIn
					: [this?.searchIn],
				"item-value": this?.valueKey,
				"clear-icon": "mdi-close primary--text",
				multiple: true,
				"auto-select-first": false,
				chips: true,
				"persistent-hint": true,
				"hide-no-data": true,
				"small-chips": true,
				clearable: true,
				"deletable-chips": true,
				"menu-props": { offsetY: true },
			};
		},
	},

	methods: {
		removeElement(idx) {
			this.$nextTick(() => {
				this._selected.splice(idx, 1);
			});
		},
		toggleAll() {
			this.$nextTick(() => {
				if (this.selected.length === this.fullArray.length) {
					this._selected = [];
				} else {
					this._selected = [...this.fullArray];
				}
			});
		},
		/** ON EDIT BUTTON PRESS, MAKE DIALOG TRUE MAKE THE DIALOG VISIBLE AND EMIT EVENT TO PARENT TO LOAD DATA */
		openDialog() {
			this._selected = [
				...this.selectedArray.map((el) => el[`${this.valueKey}`]),
			];
			this.dialog = true;
			this.$emit("openDialog");
		},
		reset() {
			this._selected = [
				...this.selectedArray.map((el) => el[`${this.valueKey}`]),
			];
		},
		/** ON SUBMIT BUTTON PRESS, MAKE DIALOG FALSE TO HIDE THE DIALOG AND EMIT EVENT TO PARENT TO SUBMIT THE DATA */
		async submit() {
			this.dialog = false;
			this.$emit("submitEditComponent", this._selected);
		},
		cancel() {
			this.dialog = false;
		},
	},

	async created() {},
};
</script>

<style scoped lang="sass">
::v-deep
  .theme--light.v-list-item--active:focus:before,
  .theme--light.v-list-item.v-list-item--highlighted:before
    opacity: 0.75


.selectall
  min-height: 48px
  cursor: pointer


.selectall:hover::after
  background-color: currentColor
  bottom: 0px
  content: ""
  left: 0
  opacity: 0.1
  pointer-events: none
  position: absolute
  right: 0
  top: 0
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)


.actions
  background-color: var(--v-kajot-anti-text-base)
  position: sticky
  z-index: 1
  bottom: 0
</style>
