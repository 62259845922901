var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _vm.noDialog
        ? _c(
            "div",
            [
              _c(
                "EditComponentAutcomplete",
                _vm._b(
                  {
                    ref: "noDialogAutocomplete",
                    attrs: { propsObject: _vm.dProps },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.$scopedSlots, function (_, name) {
                          return {
                            key: name,
                            fn: function (slotProps) {
                              return [_vm._t(name, null, null, slotProps || {})]
                            },
                          }
                        }),
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm._selected,
                      callback: function ($$v) {
                        _vm._selected = $$v
                      },
                      expression: "_selected",
                    },
                  },
                  "EditComponentAutcomplete",
                  _vm.$attrs,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.noDialog
        ? _c(
            "v-dialog",
            {
              attrs: { width: _vm.width },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _vm._t(
                          "activator",
                          function () {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: _vm.color,
                                        elevation: "1",
                                        fab: "",
                                        small: "",
                                      },
                                      on: { click: _vm.openDialog },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              ),
                            ]
                          },
                          { on: on, attrs: attrs }
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _vm._t(
                    "header",
                    function () {
                      return [
                        _c(
                          "v-card-title",
                          { staticClass: "headline primary" },
                          [
                            _vm._t(
                              "title",
                              function () {
                                return [_vm._v(" " + _vm._s(_vm.title) + " ")]
                              },
                              { title: _vm.title }
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                    { title: _vm.title }
                  ),
                  _vm._t("body", function () {
                    return [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-8" },
                        [
                          _c(
                            "EditComponentAutcomplete",
                            _vm._b(
                              {
                                ref: "noDialogAutocomplete",
                                attrs: { propsObject: _vm.dProps },
                                scopedSlots: _vm._u(
                                  [
                                    _vm._l(
                                      _vm.$scopedSlots,
                                      function (_, name) {
                                        return {
                                          key: name,
                                          fn: function (slotProps) {
                                            return [
                                              _vm._t(
                                                name,
                                                null,
                                                null,
                                                slotProps || {}
                                              ),
                                            ]
                                          },
                                        }
                                      }
                                    ),
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm._selected,
                                  callback: function ($$v) {
                                    _vm._selected = $$v
                                  },
                                  expression: "_selected",
                                },
                              },
                              "EditComponentAutcomplete",
                              _vm.$attrs,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm._t(
                    "actions-wrapper",
                    function () {
                      return [
                        _c(
                          "v-card-actions",
                          { staticClass: "actions" },
                          [
                            _vm._t(
                              "action-buttons",
                              function () {
                                return [
                                  _vm._t(
                                    "cancel-btn",
                                    function () {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "" },
                                            on: { click: _vm.cancel },
                                          },
                                          [_vm._v(" Cancel ")]
                                        ),
                                      ]
                                    },
                                    { cancel: _vm.cancel }
                                  ),
                                  _vm._t("btns-filler", function () {
                                    return [_c("v-spacer")]
                                  }),
                                  _vm._t(
                                    "submit-btn",
                                    function () {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                              disabled: _vm.loading,
                                            },
                                            on: { click: _vm.submit },
                                          },
                                          [_vm._v(" Submit ")]
                                        ),
                                      ]
                                    },
                                    { submit: _vm.submit }
                                  ),
                                ]
                              },
                              { submit: _vm.submit, cancel: _vm.cancel }
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                    { submit: _vm.submit, cancel: _vm.cancel }
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }