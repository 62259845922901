<template>
	<!-- TODO: Support key modifier for no search input reset -->
	<v-autocomplete
		ref="autocomplete"
		v-model="_selected"
		:search-input.sync="editSearch"
		@change="showmeEnter"
		:filter="filterFn"
		@keydown.enter="showmeEnter"
		v-bind="{ ...propsObject, ...$attrs }">
		<template #item="{ item, on, attrs }">
			<v-list-item
				v-on="on"
				v-bind="attrs">
				<v-list-item-content>
					<v-list-item-title>
						{{
							propsObject[`item-text`]
								? item[`${propsObject[`item-text`]}`]
								: item
						}}
					</v-list-item-title>
				</v-list-item-content>
				<!-- <v-list-item-avatar> -->
				<slot
					name="appendItem"
					:item="item"></slot>
				<!-- </v-list-item-avatar> -->
			</v-list-item>
			<!-- <v-row dense class="text-body-2 d-flex justify-space-between">
				{{
					propsObject[`item-text`] ? item[`${propsObject[`item-text`]}`] : item
				}}
				<slot name="appendItem" :item="item"> </slot>
			</v-row> -->
		</template>

		<template v-slot:selection="{ item, index }">
			<slot
				name="selection"
				:item="item"
				:index="index">
				<span>
					<v-chip
						small
						color="transparent"
						@click.stop.prevent="showOthers = false"
						v-if="!propsObject.noShorten && showOthers && index === 0"
						class="k-reset text-caption">
						<div class="primary--text blueHover">(collapse)</div>
					</v-chip>

					<v-chip
						small
						close
						:key="`gameKey-${
							propsObject[`item-value`]
								? item[`${propsObject[`item-value`]}`]
								: item
						}`"
						@click:close="removeElement(index)"
						v-if="
							propsObject.noShorten ||
							index < propsObject.maxChips ||
							showOthers
						">
						<span>
							{{
								propsObject[`item-text`]
									? item[`${propsObject[`item-text`]}`]
									: item
							}}
						</span>
					</v-chip>

					<div
						@click.stop.prevent="showOthers = true"
						v-ripple
						v-if="
							!propsObject.noShorten &&
							index === propsObject.maxChips &&
							!showOthers
						"
						class="text-caption">
						<span class="primary--text blueHover">
							(+{{ _selected.length - propsObject.maxChips }}
							{{
								_selected.length - propsObject.maxChips > 1
									? "others"
									: "other"
							}})
						</span>
					</div>

					<v-chip
						small
						color="transparent"
						@click.stop.prevent="showOthers = false"
						v-if="
							!propsObject.noShorten &&
							showOthers &&
							index === _selected.length - 1
						"
						class="k-reset text-caption">
						<div class="primary--text blueHover">(collapse)</div>
					</v-chip>
				</span>
			</slot>
		</template>
		<template v-slot:prepend-item>
			<div class="ma-3">
				<v-chip
					:disabled="_selected.length === propsObject.items.length"
					@click="toggleAll(true)"
					small
					class="mr-2">
					Select All
					{{
						_selected.length == 0 && editSearch && editSearch?.length > 0
							? "Filtererd"
							: ""
					}}
				</v-chip>
				<v-chip
					:disabled="_selected.length === 0"
					@click="toggleAll(false)"
					small>
					Deselect All
					{{
						_selected.length == 0 && editSearch && editSearch?.length > 0
							? "Filtererd"
							: ""
					}}
				</v-chip>
			</div>
		</template>
	</v-autocomplete>
</template>
<script>
import _ from "lodash";
import { isNullish } from "../../constants/helpers";

export default {
	model: {
		event: "selected",
		prop: "selected",
	},
	props: {
		propsObject: {
			type: Object,
			default: () => ({ items: [] }),
		},
		selected: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			showOthers: false,
			editSearch: null,
			dSelected: null,
		};
	},
	computed: {
		filteredItems() {
			if (isNullish(this.editSearch) || this.editSearch === "") {
				return this.propsObject.items;
			}
			return this.propsObject.items.filter((el) => {
				if (this.propsObject[`item-text`]) {
					if (this.propsObject["searchfields"]) {
						return [
							...this.propsObject[`searchfields`],
							this.propsObject["item-text"],
						]
							.reduce((agg, curr) => {
								return (agg += ` ${el[curr]}`);
							}, "")
							.toLowerCase()
							.includes(this.editSearch.toLowerCase());
					}
					return el[this.propsObject[`item-text`]]
						.toLowerCase()
						.includes(this.editSearch.toLowerCase());
				}
				return el.toLowerCase().includes(this.editSearch.toLowerCase());
			});
		},
		_selected: {
			get() {
				return this.selected;
			},
			set(val) {
				this.$emit("selected", val);
			},
		},
	},
	mounted() {
		this.$refs.autocomplete.lastItem = this.propsObject.items.length + 1;
	},
	watch: {
		editSearch(newVal) {
			if (newVal == null) {
				return;
			}
			this.changeListIndex(0);
		},
	},
	methods: {
		filterFn(el, query) {
			if (this.propsObject[`item-text`]) {
				if (this.propsObject["searchfields"]) {
					return [
						...this.propsObject[`searchfields`],
						this.propsObject["item-text"],
					]
						.reduce((agg, curr) => {
							return (agg += ` ${el[curr]}`);
						}, "")
						.toLowerCase()
						.includes(query.toLowerCase());
				}
				return el[this.propsObject[`item-text`]]
					.toLowerCase()
					.includes(query.toLowerCase());
			}
			return el.toLowerCase().includes(query.toLowerCase());
		},
		async changeListIndex(i) {
			setTimeout(async () => {
				this.$refs.autocomplete.$refs.menu.listIndex = i;
				if (this.filteredItems.length === 0) {
					return;
				}
			}, 50);
		},
		async showmeEnter(e) {
			const before = [...this.selected];
			await this.$nextTick();
			const after = e;
			const items = _.xor(after, before);
			let index;

			if (this.propsObject[`item-value`]) {
				index = this.filteredItems.findIndex(
					(el) => el[`${this.propsObject[`item-value`]}`] === items?.[0]
				);
			} else {
				index = this.filteredItems.findIndex((el) => el === items?.[0]);
			}

			if (e instanceof Event && !e.ctrlKey && this.editSearch) {
				this.editSearch = "";
			}

			await this.$nextTick();
			if (items.length === 1) {
				await this.changeListIndex(index);
				return;
			} else if (
				before.length === items.length &&
				before.length > after.length
			) {
				await this.changeListIndex(0);
				return;
			}
		},
		removeElement(idx) {
			this.$nextTick(() => {
				const tmp = [...this._selected];
				tmp.splice(idx, 1);
				this._selected = tmp;
			});
		},
		async toggleAll(mode) {
			this.$nextTick(async () => {
				if (!mode) {
					this._selected = [];
					await this.changeListIndex(0);
					return;
				} else {
					this._selected = this.filteredItems.map((el) => {
						if (this.propsObject[`item-value`])
							return el?.[`${this.propsObject[`item-value`]}`];
						return el;
					});
					this.editSearch = null;
				}
			});
		},
	},
};
</script>

<style scoped lang="sass">
// ::v-deep
//   .theme--light.v-list-item--active:focus:before,
//   .theme--light.v-list-item.v-list-item--highlighted:before
//     opacity: 0.75


.selectall
  min-height: 48px
  cursor: pointer

.blueHover
  cursor: pointer


.selectall:hover::after
  background-color: currentColor
  bottom: 0px
  content: ""
  left: 0
  opacity: 0.1
  pointer-events: none
  position: absolute
  right: 0
  top: 0
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)


.actions
  background-color: var(--v-kajot-anti-text-base)
  position: sticky
  z-index: 1
  bottom: 0
</style>
